<div class="full-height">
  <div class="content">
    <fly-header
      [oem]="userConfig != null && userConfig.isOemUser"
      [dealer]="userConfig != null && userConfig.isDealerUser"
      [orgName]="orgName"
      [customer]="customer"
      [membershipCount]="membershipCount">
    </fly-header>

    @if (pageMessages != null && pageMessages.length > 0) {
      @for (pageMessage of pageMessages; track $index) {
        @if (pageMessage.severityType != null) {
          <g-alert
            [type]="pageMessage.severityType"
            closeable="false"
            leftIcon="true"
            ><p [innerHTML]="pageMessage.text"></p
          ></g-alert>
        }
      }
    }

    <router-outlet></router-outlet>
  </div>

  <fly-footer
    [detectedLocale]="userConfig?.detectedLocale"
    [detectedCountry]="userConfig?.detectedCountry"
    [loggedIn]="userConfig != null">
  </fly-footer>

  <div id="consent_blackbar"></div>
  <div id="teconsent"></div>
</div>
