import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { environment } from 'src/environments/environment';

export interface PageMessage {
  pageName: string;
  text: string;
  severity: number;
  severityType?: string;
}

const PageMessageUrl = '/api/page-messages';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private cache$?: Observable<PageMessage[]>;
  private readonly http = inject(HttpClient);

  getPageMessage(path: string): Observable<PageMessage[]> {
    if (this.cache$ == null) {
      this.cache$ = this.loadPageMessages();
    }
    return this.cache$.pipe(
      catchError(() => {
        // Return an empty list if an error occured since warning banners shouldn't break the pages they're on.
        return [];
      }),
      map((messages: PageMessage[]) => {
        if (messages != null) {
          return messages.filter(
            (message: PageMessage) =>
              message.pageName === path ||
              message.pageName === '/' ||
              (message.pageName.endsWith('*') && path.startsWith(message.pageName.slice(0, -1)))
          );
        } else {
          // Return an empty list if we get an undefined response so that it fails softly
          return [];
        }
      })
    );
  }

  private loadPageMessages(): Observable<PageMessage[]> {
    return this.http.get(getPageMessagesUrl()).pipe(
      catchError(() => {
        return [];
      }),
      map((content) => {
        const messages = content as PageMessage[];
        return messages.map((message) => {
          return {
            pageName: message.pageName,
            text: message.text,
            severity: message.severity,
            severityType: this.getSeverityType(message)
          } as PageMessage;
        });
      }),
      tap((content) => {
        this.cache$ = of(content);
      })
    );
  }

  private getSeverityType(pageMessage: PageMessage): string | null {
    switch (pageMessage.severity) {
      case 0:
        return 'info';
      case 1:
        return 'success';
      case 2:
        return 'warning';
      case 3:
        return 'error';
      default:
        return null;
    }
  }
}

export const getPageMessagesUrl = (): string => {
  return `${environment.flyLegacyUrl}${PageMessageUrl}`;
};
