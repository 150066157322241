import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, OnInit, computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import {
  MembershipReadOnlyControllerService,
  OrganizationReadOnlyControllerService
} from '@garmin-avcloud/avcloud-fly-web-common/api/orgs-roles/read';
import { GarminGlobalStoreService } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { AuthService, CustomerService } from '@garmin-avcloud/avcloud-web-utils';
import { filter, forkJoin, of, switchMap } from 'rxjs';
import { UserConfig, UserConfigService } from 'src/app/core/services/config/user-config.service';
import { MessagesService, PageMessage } from 'src/app/core/services/messages/messages.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fly-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss'],
  standalone: false
})
export class StandardLayoutComponent implements OnInit {
  private readonly document = inject(DOCUMENT);
  private readonly garminGlobalStoreService = inject(GarminGlobalStoreService);
  private readonly userConfigService = inject(UserConfigService);
  private readonly authService = inject(AuthService);
  private readonly organizationReadonlyControllerService = inject(OrganizationReadOnlyControllerService);
  private readonly membershipReadOnlyControllerService = inject(MembershipReadOnlyControllerService);
  private readonly customerService = inject(CustomerService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly messagesService = inject(MessagesService);
  private readonly router = inject(Router);

  userConfig: UserConfig | null = null;
  pageMessages: PageMessage[] | null = null;
  orgName: string | null = null;
  customer: { name: string; email: string } | null = null;
  membershipCount = 0;

  private readonly orgUuid = computed(() => {
    const cookies = this.garminGlobalStoreService.cookies();
    const hasCookie = 'global' in cookies && 'org' in cookies['global'] && 'uuid' in cookies['global']['org'];
    return hasCookie && cookies['global']['org']['uuid'] !== '' ? (cookies['global']['org']['uuid'] as string) : null;
  });

  constructor() {
    effect(() => {
      const orgUuid = this.orgUuid();
      this.authService
        .isAuthenticated()
        .pipe(
          switchMap((isAuthenticated) => {
            if (!isAuthenticated) {
              return of(null);
            }

            return this.membershipReadOnlyControllerService.getMemberships().pipe(
              switchMap((memberships) => {
                this.membershipCount = memberships.length;

                if (
                  orgUuid != null &&
                  memberships.find((membership) => membership.organizationUuid === orgUuid) != null
                ) {
                  return this.organizationReadonlyControllerService.getOrganization(orgUuid);
                } else {
                  this.garminGlobalStoreService.removeGlobal('org');
                  return of(null);
                }
              })
            );
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((org) => {
          this.orgName = org?.name ?? null;
        });
    });

    this.router.events
      .pipe(
        filter((navigationEvent) => navigationEvent instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.messagesService
          .getPageMessage(location.pathname)
          .subscribe((messages: PageMessage[]) => (this.pageMessages = messages));
      });
  }

  ngOnInit(): void {
    this.authService
      .isAuthenticated()
      .pipe(
        switchMap((isAuthenticated) => {
          if (!isAuthenticated) {
            return forkJoin([of(null), of(null)]);
          }

          const userConfig$ = this.userConfigService.getUserConfig();
          const customerAccount$ = this.customerService.getCustomerAccount();

          return forkJoin([userConfig$, customerAccount$]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([userConfig, customerAccount]) => {
        this.userConfig = userConfig;
        if (customerAccount != null) {
          this.customer = {
            name: `${customerAccount.firstName} ${customerAccount.lastName}`,
            email: customerAccount.primaryEmailAddress
          };
        }
      });
    this.loadTrustArcScript();
  }

  private loadTrustArcScript(): void {
    const body = this.document.body as HTMLBodyElement;

    const script: HTMLScriptElement = this.document.createElement('script');
    script.src = environment.trustArcSrc;
    script.type = 'text/javascript';
    script.crossOrigin = '';

    body.appendChild(script);
  }
}
